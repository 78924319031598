import { useQuery } from "@apollo/client";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PrintIcon from "@mui/icons-material/Print";
import {
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chart from "chart.js/auto";
import { Card } from "components";
import { SubHeader } from "components/Appbar";
import { AuthContext, CompanyContext, FiltersContext } from "context";
import { GRADE_CONFIG } from "graphql/query";
import { useSelectedLanguage } from "language";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { usePrintStore } from "stores";
import { IssueEntity } from "types";
import * as SC from "./style";

const _ = require("lodash");

const LineChart = (props: any) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const daysInMillisecions = 24 * 60 * 60 * 1000;
  const { selectedDictionary } = useSelectedLanguage();
  const { companyGradeHistory, days } = props;
  const formatDate = (dateString: string) => {
    const [day, month, year] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };
  const formatDate2 = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  function getGradeForDate(
    grades: Map<any, any>,
    date: string
  ): number | undefined {
    for (const [key, value] of grades.entries()) {
      if (new Date(formatDate(key)) <= new Date(formatDate(date))) {
        return value;
      }
    }
    return undefined;
  }
  const dateFromXDaysAgo = (timeInMS: number) => {
    return new Date(Date.now() - timeInMS);
  };

  const sortedGrades = [...companyGradeHistory].sort((a: any, b: any) =>
    new Date(formatDate(a?.date)) > new Date(formatDate(b?.date)) ? -1 : 1
  );

  const datesMap: any = new Map();

  [...sortedGrades].forEach((gradeHistory: { date: string; grade: number }) => {
    if (!datesMap.has(gradeHistory.date))
      datesMap.set(gradeHistory.date, gradeHistory.grade);
  });
  const startingDate = [...datesMap.entries()].pop()[0] || undefined;
  const newDays = Math.ceil(
    (Date.now() - new Date(formatDate(startingDate)).getTime()) /
      daysInMillisecions
  );
  const dataSet: any[] = [];
  for (let x = days === -1 ? newDays : days; x >= 0; x--) {
    dataSet.push({
      date: formatDate2(dateFromXDaysAgo(daysInMillisecions * x)),
      grade: getGradeForDate(
        datesMap,
        formatDate2(dateFromXDaysAgo(daysInMillisecions * x))
      ),
    });
  }

  useEffect(() => {
    const ctx = chartRef?.current?.getContext("2d");
    if (!ctx) return;

    const chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: dataSet.map((l: any) => l.date),
        datasets: [
          {
            label: `${selectedDictionary.funds.grade}`,
            data: dataSet.map((l: any) => l.grade),
            pointRadius: 0,
            backgroundColor: "rgba(46,49,58,0.2)",
            borderWidth: 1,
            borderColor: "rgba(46,49,58,1)",
            stepped: true,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          axis: "x",
        },
        plugins: {},
        scales: {
          x: {
            title: {
              display: true,
              text: `${(selectedDictionary as any).funds.date}`,
            },
          },

          y: {
            title: {
              display: true,
              text: `${(selectedDictionary as any).funds.weightedGrade}`,
            },
            beginAtZero: true,
            suggestedMax: 100,
          },
        },
      },
    });
    return () => chart.destroy();
  }, [days]);
  return <canvas ref={chartRef}></canvas>;
};

const ReportScreen = () => {
  const {
    company,
    setCompanyID,
    setIssuerId,
    issuerId,
    getCompany,
    config,
    getConfig,
    getCompanyData,
    getCompanyHistory,
    loadingCompany,
    loadingConfig,
    loadingCompanyData,
    loadingcompanyHistory,
    companyData,
    companyHistory,
  } = useContext(CompanyContext);
  const { getIssues, issues, loadingIssues } = useContext(FiltersContext);
  const { userData, getMe } = useContext(AuthContext);
  const { printing, setPrinting } = usePrintStore();
  const { id } = useParams();
  const [days, setDays] = useState(-1);
  const matchesSm = useMediaQuery("(min-width:600px)");
  useEffect(() => {
    if (!loadingIssues && !issues?.length) getIssues();
  }, [getIssues, issues, loadingIssues]);

  useEffect(() => {
    setCompanyID(Number(id));
    if (!loadingCompany && (!company || company.id !== id)) {
      getCompany();
    }
  }, [company, id, setCompanyID, getCompany, loadingCompany]);

  useEffect(() => {
    setIssuerId(`${company.issuerId}`);

    if (!loadingCompanyData && (!company || company.id !== id)) {
      getCompanyData();
    }
    if (!loadingcompanyHistory && (!company || company.id !== id)) {
      getCompanyHistory();
    }
  }, [company]);

  useEffect(() => {
    if (!loadingConfig) getConfig();
  }, [getConfig, loadingConfig]);

  useEffect(() => {
    if (!userData?.role) getMe();
  }, [getMe, userData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, loading, error } = useQuery(GRADE_CONFIG, {
    variables: {
      publicationState: "LIVE",
    },
  });

  const [gradeConfig, setGradeConfig] = useState(undefined);

  useEffect(() => {
    if (loading === false && data) {
      setGradeConfig(
        data?.gradeToLetterConfig?.data?.attributes?.config?.map((c: any) => ({
          type: c.type,
          ranges: c.ranges,
          penalty: c.penalty,
        }))
      );
    }
  }, [loading, data]);

  const {
    activity,
    analystSummary,
    esgGrade,
    social,
    environmental,
    governance,
    badActivities,
    sdgActivities,
    climateGrade,
  } = company;
  const chunkIssues = (issues: any[]): any[] => {
    if (!issues) return [];
    const chunkSize = 3;
    const chunks: any[] = [];
    for (let i = 0; i < issues.length; i += chunkSize) {
      chunks.push(issues.slice(i, i + chunkSize));
    }
    return chunks;
  };
  const [toggle, setToggle] = useState(false);
  const governanceIssues = chunkIssues(governance.issues);
  const socialIssues = chunkIssues(social.issues);
  const environmentalIssues = chunkIssues(environmental.issues);

  const hasIncidents =
    !_.isEmpty(social.incidents) ||
    !_.isEmpty(governance.incidents) ||
    !_.isEmpty(environmental.incidents);

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: async () => {
      setPrinting(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();

  const displayIncidentsList = (incidentsList: any, areaTitle: string) => {
    return (
      <>
        <Grid container spacing={2} alignItems="stretch" className="padding">
          <Grid item xs={12} container direction="column">
            <Typography variant="h4" padding="0.5rem .4rem 0">
              {areaTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card.IncidentsReports {...incidentsList} />
          </Grid>
        </Grid>
        <div style={{ breakAfter: "page" }}></div>
      </>
    );
  };

  // Provide Loading modal with exact page height.
  const hasEventsFlag = company.eventsFlag && company.eventsFlag !== "None";
  const appbarDiv = document.querySelector(".greeneye-appbar");
  const screenWrapperStyle = loadingCompany
    ? {
        height:
          appbarDiv != null && appbarDiv.clientHeight > 0
            ? window.innerHeight - appbarDiv.clientHeight + "px"
            : "90vh",
        overflow: "hidden",
      }
    : {};

  return (
    <SC.ReportScreenWrapper
      ref={componentRef}
      id="print-component"
      style={{
        ...screenWrapperStyle,
        display: printing ? "block" : "flex",
        direction: "rtl",
      }}
    >
      <>
        <SubHeader />
      </>

      {userData.letterGrading && !loadingCompany ? (
        <>
          <Card.LetterGradeAvatar
            {...{
              esgGrade,
              environmental,
              social,
              governance,
              gradeConfig,
              company,
              activity,
            }}
          ></Card.LetterGradeAvatar>
        </>
      ) : (
        <>
          <Grid container spacing={2} alignItems="stretch" className="padding">
            <Grid item md={4} xs={printing ? 4 : 12}>
              <Card.Basic
                content={activity}
                name={(selectedDictionary as any).reportIndexCell1}
              />
            </Grid>
            <Grid item md={8} xs={printing ? 8 : 12}>
              <Card.ESGGrade {...esgGrade} />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="stretch" className="padding">
            <Grid item xs={4}>
              <Card.Progress
                {...environmental}
                name={(selectedDictionary as any).reportIndexCell2}
              />
            </Grid>
            <Grid item xs={4}>
              <Card.Progress
                {...social}
                name={(selectedDictionary as any).reportIndexCell3}
              />
            </Grid>
            <Grid item xs={4}>
              <Card.Progress
                {...governance}
                name={(selectedDictionary as any).reportIndexCell4}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={2} alignItems="stretch" className="padding">
        <Grid item xs={userData?.hasClimateGrade ? 9 : 12}>
          <Card.Basic
            content={analystSummary}
            name={(selectedDictionary as any).reportIndexCell5}
          />
        </Grid>
        {userData?.hasClimateGrade && (
          <Grid item xs={3}>
            <Card.ClimateCard
              {...{
                letterGrading: userData.letterGrading,
                ...climateGrade,
                ...companyData,
                environmental,
                social,
                governance,
              }}
            />
          </Grid>
        )}
      </Grid>
      <div style={{ breakAfter: "page" }}></div>

      {userData?.hasCompanyGraph && !printing && (
        <Grid container spacing={2} alignItems="stretch" className="padding">
          <Grid item xs={12}>
            <SC.Card variant="outlined">
              <CardHeader
                title={(selectedDictionary as any).progressionGraph}
              ></CardHeader>
              <CardContent>
                <IconButton
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                  aria-label="expand/collapse graph"
                  size="small"
                >
                  {toggle ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <Collapse in={toggle} timeout="auto" unmountOnExit>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      size="small"
                      color={days === -1 ? "success" : "primary"}
                      disableElevation
                      disableRipple
                      sx={{ margin: "0.2rem" }}
                      onClick={() => {
                        setDays(-1);
                      }}
                    >
                      <Typography color="white">{"All"}</Typography>
                    </Button>
                    {/* <Button
                      variant="contained"
                      size="small"
                      disableElevation
                      disableRipple
                      sx={{ margin: "0.2rem" }}
                      onClick={() => {
                        setDays(30);
                      }}
                    >
                      <Typography color="white">{"30D"}</Typography>
                    </Button> */}
                    <Button
                      variant="contained"
                      size="small"
                      color={days === 364 ? "success" : "primary"}
                      disableElevation
                      disableRipple
                      sx={{ margin: "0.2rem" }}
                      onClick={() => {
                        setDays(364);
                      }}
                    >
                      <Typography color="white">{"1Y"}</Typography>
                    </Button>
                  </Box>

                  <Grid item xs={8}>
                    <div>
                      {companyHistory?.gradeHistory?.length > 0 ? (
                        <LineChart
                          {...{
                            companyGradeHistory: companyHistory?.gradeHistory,
                            days: days,
                          }}
                        ></LineChart>
                      ) : (
                        (selectedDictionary as any).noHistoryWarning
                      )}
                    </div>
                  </Grid>
                </Collapse>
              </CardContent>
            </SC.Card>
          </Grid>
        </Grid>
      )}

      {!printing && hasEventsFlag && (
        <Grid container spacing={2} alignItems="stretch" className="padding">
          <Grid item xs={12}>
            <Card.FlagExplanationCard />
          </Grid>
        </Grid>
      )}
      {hasIncidents && (
        <>
          <Grid item xs={12} container direction="column">
            <Typography variant="h3" padding="1rem .4rem 0">
              {(selectedDictionary as any).reportIndexCell6}
            </Typography>
            {!_.isEmpty(environmental.incidents) &&
              displayIncidentsList(
                environmental,
                (selectedDictionary as any).reportIndexCell15
              )}
            {!_.isEmpty(social.incidents) &&
              displayIncidentsList(
                social,
                (selectedDictionary as any).reportIndexCell16
              )}
            {!_.isEmpty(governance.incidents) &&
              displayIncidentsList(
                governance,
                (selectedDictionary as any).reportIndexCell17
              )}
          </Grid>
        </>
      )}
      <Grid container spacing={2} alignItems="stretch" className="padding">
        <Grid item xs={12}>
          <Card.Activity
            name={(selectedDictionary as any).reportIndexCell7}
            activities={sdgActivities}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="stretch" className="padding">
        <Grid item xs={12}>
          <Card.Activity
            name={(selectedDictionary as any).reportIndexCell8}
            activities={badActivities}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="stretch" className="padding">
        {config?.featureIssue &&
          environmental?.issues?.some(
            (i: IssueEntity) => i.name === config?.featureIssue
          ) && (
            <Grid item xs={6}>
              <Card.ClimateChangePreparation
                {...environmental?.issues?.find(
                  (i: IssueEntity) => i.name === config?.featureIssue
                )}
              />
            </Grid>
          )}
        <Grid item xs={12}>
          <Card.ActiveCountries />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        className={`force-page-break padding ${
          userData.role === "Analyst" && "hide-for-analyst"
        }`}
      >
        <Grid item xs={12}>
          <Card.CompaniesComparison {...{ gradeConfig }} />
        </Grid>
      </Grid>
      {printing && hasEventsFlag && (
        <Grid className="page-break padding">
          <Card.FlagExplanationCard />
        </Grid>
      )}
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        className="page-break padding"
      >
        <Typography variant="h3" padding="2rem .4rem 0.4rem">
          {(selectedDictionary as any).reportIndexCell9}
        </Typography>
        <Grid item xs={12}>
          <Card.CompanyEvaluation
            {...{ ...environmental, gradeConfig: gradeConfig, company }}
            title={(selectedDictionary as any).reportIndexCell18}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        className="page-break padding"
      >
        <Grid item xs={12}>
          <Card.CompanyEvaluation
            {...{ ...social, gradeConfig, company }}
            title={(selectedDictionary as any).reportIndexCell19}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        className="page-break padding"
      >
        <Grid item xs={12}>
          <Card.CompanyEvaluation
            {...{ ...governance, gradeConfig, company }}
            title={(selectedDictionary as any).reportIndexCell20}
          />
        </Grid>
      </Grid>
      <div style={{ breakAfter: "page" }}></div>
      <Grid container spacing={2} alignItems="stretch" className="padding">
        <Grid item xs={12} container direction="column">
          <Typography variant="h3" padding="1.8rem .4rem 0">
            {(selectedDictionary as any).reportIndexCell10}
          </Typography>
          <Typography variant="h4" padding="1rem .4rem 0">
            {(selectedDictionary as any).reportIndexCell11}
          </Typography>
        </Grid>
        {printing ? (
          environmentalIssues.map((issues: IssueEntity[], i) => (
            <>
              <Grid item xs={12}>
                <Card.CompanyDetailReport
                  issues={issues}
                  category="environmental"
                />
              </Grid>
              <div style={{ breakAfter: "page" }}></div>
            </>
          ))
        ) : (
          <Grid item xs={12}>
            <Card.CompanyDetailReport
              {...environmental}
              category="environmental"
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} alignItems="stretch" className="padding">
        <Typography variant="h4" padding="2rem .4rem 0">
          {(selectedDictionary as any).reportIndexCell12}
        </Typography>
        {printing ? (
          socialIssues.map((issues: IssueEntity[], i) => (
            <>
              <Grid item xs={12}>
                <Card.CompanyDetailReport issues={issues} category="social" />
              </Grid>
              <div style={{ breakAfter: "page" }}></div>
            </>
          ))
        ) : (
          <Grid item xs={12}>
            <Card.CompanyDetailReport {...social} category="social" />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} alignItems="stretch" className=" padding">
        <Typography variant="h4" padding="2rem .4rem 0">
          {(selectedDictionary as any).reportIndexCell13}
        </Typography>
        {printing ? (
          governanceIssues.map((issues: IssueEntity[], i) => (
            <>
              <Grid item xs={12}>
                <Card.CompanyDetailReport
                  issues={issues}
                  category="governance"
                />
              </Grid>
              <div style={{ breakAfter: "page" }}></div>
            </>
          ))
        ) : (
          <Grid item xs={12}>
            <Card.CompanyDetailReport {...governance} category="governance" />
          </Grid>
        )}
      </Grid>
      {company.source && (
        <Grid sx={{ breakBefore: "always" }} item xs={12}>
          <Card.Source />
        </Grid>
      )}
      {config.disclaimer && (
        <Grid item xs={12}>
          <Card.Disclaimer disclaimer={config.disclaimer} />
        </Grid>
      )}
      <Box
        sx={{
          position: "fixed",
          bottom: "3rem",
          left: "3rem",
          zIndex: "1300",
        }}
        className="hide-for-print"
      >
        <Button
          variant="contained"
          size="large"
          disableElevation
          disableRipple
          onClick={handlePrint}
        >
          <PrintIcon />
          <Typography variant="h5" color="white" sx={{ pr: ".8rem" }}>
            {(selectedDictionary as any).reportIndexCell14}
          </Typography>
        </Button>
      </Box>
    </SC.ReportScreenWrapper>
  );
};

export default ReportScreen;
