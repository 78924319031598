import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Card } from "components";
import { useSelectedLanguage } from "language";
import { IssueEntity } from "types";
import * as SC from "./style";

interface Props {
  issues: IssueEntity[];
  category: string;
}

const OutlinedCard = (props: Props) => {
  const { issues, category } = props;
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();

  const categoryMap: { [key: string]: string } = {
    environmental: "ס",
    social: "ח",
    governance: "מ",
  };
  const regex = /([סמח])\s*(\d+(?:\.\d)?)/;
  const matchRequiredLength = 3;
  return (
    <SC.Card variant="outlined">
      <CardContent
        sx={{
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <Grid container sx={{ background: "#FBFBFB" }} p="1rem">
          <Grid item xs={3}>
            <Typography variant="h4">
              {(selectedDictionary as any).companyDetailReportCell1}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h4">
              {(selectedDictionary as any).companyDetailReportCell2}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">
              {(selectedDictionary as any).companyDetailReportCell3}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {issues
            ?.filter((issue: IssueEntity) => {
              const match = issue.name.match(regex);
              const isInvalidMatch =
                !Array.isArray(match) || match.length !== matchRequiredLength;
              if (isInvalidMatch || match[1] === categoryMap[category]) {
                return true;
              }
            })
            .map((issue: IssueEntity, i: number) => {
              return (
                <Box key={i}>
                  <Card.CompanyIssue {...issue} />
                  {i !== issues?.length - 1 && <Divider />}
                </Box>
              );
            })}
        </Grid>
      </CardContent>
    </SC.Card>
  );
};

export default OutlinedCard;
