export enum AuthStatus {
  SignedIn = "signedin",
  SignedOut = "signedout",
  Loading = "loading",
}

export enum BadActivityTypes {
  FOSSIL_FUELS = "FOSSIL_FUELS",
  GAMBLING = "GAMBLING",
  PESTICIDES = "PESTICIDES",
  TOBACCO = "TOBACCO",
  INDISCRIMINATE_WEAPONS = "INDISCRIMINATE_WEAPONS",
  CHEMICALS_RESTRICTED_BY_CONVENTIONS = "CHEMICALS_RESTRICTED_BY_CONVENTIONS",
  NATURAL_RESOURCE_MINING = "NATURAL_RESOURCE_MINING",
  MEDICAL_PRODUCTS_TESTED_ON_ANIMALS = "MEDICAL_PRODUCTS_TESTED_ON_ANIMALS",
  NON_MEDICAL_PRODUCTS_TESTED_ON_ANIMALS = "NON_MEDICAL_PRODUCTS_TESTED_ON_ANIMALS",
  SECURITY_SYSTEMS = "SECURITY_SYSTEMS",
  NUCLEAR_ENERGY_INVOLVEMENT = "NUCLEAR_ENERGY_INVOLVEMENT",
  NUCLEAR_WEAPON_INVOLVEMENT = "NUCLEAR_WEAPON_INVOLVEMENT",
  HANDHEL_WEAPONS = "HANDHEL_WEAPONS",
  PORNOGRAPHY = "PORNOGRAPHY",
}

export enum SearchOptions {
  COMPANY_NAME = "COMPANY_NAME",
  COMPANY_ISIN = "COMPANY_ISIN",
  SECURITY_TASE_ID = "SECURITY_TASE_ID",
  SECURITY_ISIN = "SECURITY_ISIN",
  SECURITY_NAME = "SECURITY_NAME",
  ISSUER_ID = "ISSUER_ID",
}

export enum DrawerInputTypes {
  Checkbox,
  Slider,
  Radio,
}

export enum InvolvementLevel {
  VERY_LOW = "VERY_LOW",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  VERY_HIGH = "VERY_HIGH",
}
export enum InvolvementLevelMap {
  VERY_LOW = "Very Low",
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
  VERY_HIGH = "Very High",
}

export enum MiscFilterTypes {
  SOME = "SOME",
  NONE = "NONE",
  CRITICAL = "CRITICAL",
}

export enum RelativeToGrade {
  ABOVE = "ABOVE",
  EQUAL = "EQUAL",
  BELOW = "BELOW",
}
export enum IncidentsFilter {
  INCIDENTS_FLAG_NONE = "INCIDENTS_FLAG_NONE",
  INCIDENTS_FLAG_HIGH = "INCIDENTS_FLAG_HIGH",
  INCIDENTS_FLAG_CRITICAL = "INCIDENTS_FLAG_CRITICAL",
  NO_INCIDENTS_FLAG_NONE = "NO_INCIDENTS_FLAG_NONE",
}

export enum SDGActivityTypes {
  RENEWABLE_ENERGY = "RENEWABLE_ENERGY",
  HEALTH = "HEALTH",
  FOOD_SECURITY = "FOOD_SECURITY",
  SAFETY = "SAFETY",
  FINANCIAL_ACCESSIBILITY = "FINANCIAL_ACCESSIBILITY",
  PUBLIC_TRANSPORT = "PUBLIC_TRANSPORT",
  POLLUTION_REDUCTION = "POLLUTION_REDUCTION",
  EDUCATION = "EDUCATION",
  ELECTRICITY = "ELECTRICITY",
  TELECOMMUNICATIONS = "TELECOMMUNICATIONS",
  WATER_AND_SANITATION = "WATER_AND_SANITATION",
  BIODIVERSITY_PRESERVATION = "BIODIVERSITY_PRESERVATION",
  CLIMATE_CHANGE_ADAPTATION = "CLIMATE_CHANGE_ADAPTATION",
  CLIMATE_CHANGE_MITIGATION = "CLIMATE_CHANGE_MITIGATION",
}

export interface Activity {
  id: number;
  type: BadActivityTypes | SDGActivityTypes;
  description?: string;
  involvement: InvolvementLevel;
}

export interface Fund {
  fundId: number;
  fundName: string;
  fundMarketCap?: number;
  fundAssessedPercentage?: number;
  fundWeightedGrade: number;
  percentAssessedByGreeneye: number;
  fundDate: string;
  badActivitiesPercent: number;
  percentOfRedFlagSecurities: number;
  sdgPercent: number;
  assets: Asset[];
  fundHistory: fundHistory[];
  benchmark: Benchmark;
}

export interface Benchmark {
  fundName: string;
  fundWeightedGrade: number;
  percentAssessedByGreeneye: number;
  badActivitiesPercent: number;
  percentOfRedFlagSecurities: number;
  sdgPercent: number;
  fundHistory: fundHistory[];
  assets: Asset[];
}

export interface fundHistory {
  weightedGrade: number;
  fundDate: string;
  name?: string;
  badActivitiesPercent?: string;
  percentOfRedFlagSecurities?: string;
  sdgPercent?: string;
}

export interface Asset {
  assetId: number;
  assetName: string;
  assetType: number;
  amount?: number;
  valueInNis?: number;
  percentOfTheFund: number;
  isAssessed: boolean | undefined;
  ticker: string;
  weight: number;
  grade: number;
  normalizedWeight: number;
  weightedGrade: number;
  isRedFlag: boolean;
}

export interface Company {
  id: number;
  issuerId: number;
  name: string;
  name_en: string;
  ticker: string;
  isin: string;
  source: string;
  securities: string[];
  primarySector: { name: string };
  greeneyeSector: { name: string };
  analystSummary: string;
  esgGrade: {
    grade?: number;
    average?: number;
    gradeRelativeToSectorAverage?: RelativeToGrade;
    percentileInSector?: number;
    percentileInGeneral?: number;
    accGradesWithoutFactor?: number;
    letterGrade?: string;
  };
  environmental: {
    grade?: number;
    average?: number;
    gradeRelativeToSectorAverage?: RelativeToGrade;
  };
  governance: {
    grade?: number;
    average?: number;
    gradeRelativeToSectorAverage?: RelativeToGrade;
  };
  social: {
    grade?: number;
    average?: number;
    gradeRelativeToSectorAverage?: RelativeToGrade;
  };
  badActivities: Activity[];
  sdgActivities: Activity[];
  eventsFlag: string;
  flagExplanation?: string;
  events: EventEntity[];
  indexes: TextValuePair<string>[];
}

export interface CsvDetailHeader {
  label: string;
  key: string;
}

export interface CompanyWithIssues {
  id: number;
  taseId?: { taseId: number };
  issuerId: number;
  name: string;
  name_en?: string;
  issues: {
    label: string;
    risk: number;
    management: number;
    position: number;
  }[];
  ticker?: string;
  isin?: string;
  primarySector?: { name: string };
  securities: string[];
  analystSummary: string;
  esgGrade: {
    grade?: number;
    average?: number;
    gradeRelativeToSectorAverage?: RelativeToGrade;
    percentileInSector?: number;
    percentileInGeneral?: number;
    accGradesWithoutFactor?: number;
    letterGrade?: string;
  };
  environmental: {
    grade?: number;
    average?: number;
    gradeRelativeToSectorAverage?: RelativeToGrade;
  };
  governance: {
    grade?: number;
    average?: number;
    gradeRelativeToSectorAverage?: RelativeToGrade;
  };
  social: {
    grade?: number;
    average?: number;
    gradeRelativeToSectorAverage?: RelativeToGrade;
  };
  badActivities: Activity[];
  sdgActivities: Activity[];
  eventsFlag: string;
  flagExplanation?: string;
  events: EventEntity[];
}

export interface CompanyLine {
  security?: string;
  issuerId: number;
  name: string;
  ticker: string;
  isin: string;
  sector: string;
  esgScore?: number;
  analystSummary?: string;
  relativeEsgScore?: string;
  percentileInSector?: number;
  percentileInGeneral?: number;
  envScore?: number;
  relativeEnvScore?: string;
  socialScore?: number;
  relativeSocialScore?: string;
  govScore?: number;
  relativeGovScore?: string;
  events?: string;
  accGradesWithoutFactor?: number;
  eventsFlag: string;
  flagExplanation?: string;
  badActivities?: string;
  esgActivities?: string;
  letterGrade?: string;
}

export interface CompanyReport {
  id: string;
  issuerId: string;
  name: string;
  name_en: string;
  ticker: string;
  isin: string;
  activity: string;
  analysisDate: Date;
  analystName: string;
  analystSummary: string;
  engagement: string;
  badActivities: Activity[];
  primarySector: { name: string };
  greeneyeSector: { name: string };
  eventsFlag: string;
  flagExplanation?: string;
  source: string;
  events: EventEntity[];
  sdgActivities: Activity[];
  countries: { name: string; isDeveloped: boolean }[];
  sectorCompanies: SectorCompany[];
  companyGradeHistory: any[];
  environmental: {
    name: string;
    grade: number;
    average: number;
    min: number;
    max: number;
    relevance: InvolvementLevel;
    sectorDescription: string;
    companyDescription: string;
    issues: IssueEntity[];
    incidents: Incident[];
  };
  governance: {
    name: string;
    grade: number;
    average: number;
    min: number;
    max: number;
    relevance: InvolvementLevel;
    sectorDescription: string;
    companyDescription: string;
    issues: IssueEntity[];
    incidents: Incident[];
  };
  social: {
    name: string;
    grade: number;
    average: number;
    min: number;
    max: number;
    relevance: InvolvementLevel;
    sectorDescription: string;
    companyDescription: string;
    issues: IssueEntity[];
    incidents: Incident[];
  };
  esgGrade: {
    letterGrade: string;
    grade: number;
    average: number;
    min: number;
    max: number;
    comment: string;
  };
  climateGrade: {
    letterGrade: string;
    grade: number;
  };
}
export interface CompanyData {
  percentageOfEnablingActivities?: number;
  percentageOfDelayingActivities?: number;
}
export interface CompanyGradeHistory {
  issuerId?: number;
  gradeHistory: any[];
}

export interface CountryEntity {
  name: string;
  isDeveloped: boolean;
}

export interface EventEntity {
  id: string;
  affectedArea?: string;
  severity?: number;
  response?: number;
  description?: string;
  isRecurring?: boolean;
  date?: Date;
}

export interface FilterItem<T = any> {
  title: string;
  options?: TextValuePair<T>[];
  id: string;
  values?: TextValuePair<T>[];
  type: DrawerInputTypes;
}

export interface incidentsFlagsFilter {
  key: string;
  flagType: string;
}

export interface FiltersPayload {
  badActivities?: (BadActivityTypes | MiscFilterTypes)[];
  incidents?: boolean;
  eventsFlags?: string[];
  greeneyeSector?: string[];
  indexes?: string[];
  isBadActivities?: boolean;
  isSdgActivities?: boolean;
  minPercentileGrade?: number;
  maxPercentileGrade?: number;
  maxEnvGrade?: number;
  maxGovGrade?: number;
  maxGrade?: number;
  maxSocialGrade?: number;
  minEnvGrade?: number;
  minGovGrade?: number;
  minGrade?: number;
  minSocialGrade?: number;
  minEsgPercentileGrade?: number;
  maxEsgPercentileGrade?: number;
  primarySector?: string[];
  relativeEnvGrade?: RelativeToGrade[];
  relativeGovGrade?: RelativeToGrade[];
  relativeGrade?: RelativeToGrade[];
  relativeSocialGrade?: RelativeToGrade[];
  sdgActivities?: SDGActivityTypes[];
  letterGrades?: string[];
  searchText?: string;
  selectedOption?: string;
  user?: string;
}

export interface IssueEntity {
  management: number;
  description?: string;
  risk: number;
  name: string;
  group: string;
  position: number;
  label: string;
  area: string;
  subIssues: TextValuePair<string>[];
}
export interface Incident {
  mitigation: number;
  description?: string;
  severity: number;
  name: string;
  date: string;
  mitigationDescription?: string;
  incidentCheckboxes?: nameLabelPair[];
}

export interface LoadingProps {
  width?: string;
  height?: string;
  filter?: string;
}

export interface LoginDetails {
  password: string;
  identifier: string;
}

export interface LoginResult {
  success: boolean;
  message: string;
}

export interface SectorCompany {
  id: string;
  name: string;
  grade: number;
  environmentalGrade: number;
  socialGrade: number;
  governanceGrade: number;
  events: boolean;
  eventsFlag: string;
}

export interface TextValuePair<T> {
  text: string;
  value: T;
}
export interface nameLabelPair {
  name: string;
  label: string;
}

interface Titles {
  [key: string]: string | undefined;
}
export interface UserData {
  id: string;
  username: string;
  role: string;
  letterGrading: boolean;
  hasFundsFeature: boolean;
  orgId: number | null;
  hasCompanyGraph: boolean;
  hasClimateGrade: boolean;
  selectedSearchOption: string | null;
}

export const csvHeaders = [
  { label: "נייר ערך", key: "security" },
  { label: "מספר מנפיק", key: "issuerId" },
  { label: "שם חברה", key: "name" },
  { label: "Ticker", key: "ticker" },
  { label: "ISIN", key: "isin" },
  { label: "ענף", key: "sector" },
  { label: "ESG ציון", key: "esgScore" },
  { label: "ESG דירוג", key: "letterGrade" },
  { label: "דירוג ESG ביחס לענף", key: "relativeEsgScore" },
  { label: "אחוזון בענף", key: "percentileInSector" },
  { label: "אחוזון כללי", key: "percentileInGeneral" },
  { label: "ציון סביבה", key: "envScore" },
  { label: "דירוג סביבה ביחס לענף", key: "relativeEnvScore" },
  { label: "ציון חברה", key: "socialScore" },
  { label: "דירוג חברה ביחס לענף", key: "relativeSocialScore" },
  { label: "ציון ממשל", key: "govScore" },
  { label: "דירוג ממשל ביחס לענף", key: "relativeGovScore" },
  { label: "אירועים קריטיים", key: "eventsFlag" },
  { label: "פעילויות שנויות במחלוקת", key: "badActivities" },
  { label: "שירותים ומוצרים עם השפעה חיובית", key: "esgActivities" },
];

export const csvHeadersEn = [
  { label: "Security", key: "security" },
  { label: "Issuer Id", key: "issuerId" },
  { label: "Name", key: "name" },
  { label: "Ticker", key: "ticker" },
  { label: "ISIN", key: "isin" },
  { label: "Sector", key: "sector" },
  { label: "ESG Grade", key: "esgScore" },
  { label: "ESG Grade in Relation to the Sector", key: "relativeEsgScore" },
  { label: "Percentile in Sector", key: "percentileInSector" },
  { label: "Percentile in General", key: "percentileInGeneral" },
  { label: "Environment Grade", key: "envScore" },
  {
    label: "Environment Grade in Relation to the Sector",
    key: "relativeEnvScore",
  },
  { label: "Social Grade", key: "socialScore" },
  {
    label: "Social Grade in Relation to the Sector",
    key: "relativeSocialScore",
  },
  { label: "Governance Grade", key: "govScore" },
  {
    label: "Governance Grade in Relation to the Sector",
    key: "relativeGovScore",
  },
  { label: "Events", key: "eventsFlag" },
  { label: "Controversial Activities", key: "badActivities" },
  {
    label: "Services and products with a positive impact",
    key: "esgActivities",
  },
];

export const TypeTitles: Titles = {
  ABOVE: "מעל הממוצע",
  EQUAL: "שווה לממוצע",
  BELOW: "מתחת לממוצע",
  VERY_LOW: "מעורבות נמוכה מאד",
  LOW: "מעורבות נמוכה",
  MEDIUM: "מעורבות בינונית",
  HIGH: "מעורבות גבוהה",
  VERY_HIGH: "מעורבות גבוהה מאד",
  RENEWABLE_ENERGY: "אנרגיה מתחדשת",
  HEALTH: "בריאות",
  FOOD_SECURITY: "בטחון תזונתי",
  SAFETY: "בטיחות",
  FINANCIAL_ACCESSIBILITY: "הנגשה פיננסית",
  PUBLIC_TRANSPORT: "הסעת המונים",
  POLLUTION_REDUCTION: "הפחתת זיהום",
  EDUCATION: "חינוך",
  ELECTRICITY: "חשמל",
  TELECOMMUNICATIONS: "טלקומוניקציה",
  WATER_AND_SANITATION: "מים וסניטציה",
  BIODIVERSITY_PRESERVATION: "שימור המגוון הביולוגי",
  CLIMATE_CHANGE_ADAPTATION: "שינוי אקלים - הסתגלות",
  CLIMATE_CHANGE_MITIGATION: "שינוי אקלים - הפחתה",
  FOSSIL_FUELS: "דלקים פוסיליים - גז, פחם, נפט",
  GAMBLING: "הימורים",
  PESTICIDES: "חומרי הדברה",
  TOBACCO: "טבק",
  INDISCRIMINATE_WEAPONS: "ייצור נשק שלא מבדיל",
  CHEMICALS_RESTRICTED_BY_CONVENTIONS:
    "כימיקלים אסורים לשימוש על פי אמנות בינלאומיות",
  NATURAL_RESOURCE_MINING: "כריית משאבי טבע",
  MEDICAL_PRODUCTS_TESTED_ON_ANIMALS: "מוצרים שנוסו על בעלי חיים לצורך רפואי",
  NON_MEDICAL_PRODUCTS_TESTED_ON_ANIMALS:
    "מוצרים שנוסו על בעלי חיים שלא לצורך רפואי",
  SECURITY_SYSTEMS: "מכירות של מערכות בטחוניות",
  NUCLEAR_ENERGY_INVOLVEMENT: "מעורבות אנרגיה גרעינית",
  NUCLEAR_WEAPON_INVOLVEMENT: "מעורבות נשק גרעיני",
  HANDHEL_WEAPONS: "נשק אזרחי",
  PORNOGRAPHY: "פורנוגרפיה",
};

export const TypeTitlesEn: Titles = {
  ABOVE: "Above",
  EQUAL: "Equal",
  BELOW: "Below",
  VERY_LOW: "Very Low",
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  VERY_HIGH: "Very High",
  RENEWABLE_ENERGY: "Renewable Energy",
  HEALTH: "Health",
  FOOD_SECURITY: "Food Security",
  SAFETY: "Safety",
  FINANCIAL_ACCESSIBILITY: "Financial Accessibility",
  PUBLIC_TRANSPORT: "Public Transport",
  POLLUTION_REDUCTION: "Pollution Reduction",
  EDUCATION: "Education",
  ELECTRICITY: "Electricity",
  TELECOMMUNICATIONS: "Telecommunications",
  WATER_AND_SANITATION: "Water And Sanitation",
  BIODIVERSITY_PRESERVATION: "Biodiversity Preservation",
  CLIMATE_CHANGE_ADAPTATION: "Climate Change Adaptation",
  CLIMATE_CHANGE_MITIGATION: "Climate Change Mitigation",
  FOSSIL_FUELS: "Fossil Fuels",
  GAMBLING: "Gambling",
  PESTICIDES: "Pesticides",
  TOBACCO: "Tobacco",
  INDISCRIMINATE_WEAPONS: " Indiscriminate Weapons",
  CHEMICALS_RESTRICTED_BY_CONVENTIONS: "Chemicals Restricted by Conventions",
  NATURAL_RESOURCE_MINING: "Natural Resource Mining",
  MEDICAL_PRODUCTS_TESTED_ON_ANIMALS: "Medical Products Tested on Animals",
  NON_MEDICAL_PRODUCTS_TESTED_ON_ANIMALS:
    "Non Medical Products Tested on Animals",
  SECURITY_SYSTEMS: "Security Systems",
  NUCLEAR_ENERGY_INVOLVEMENT: "Nuclear Energy Involvment",
  NUCLEAR_WEAPON_INVOLVEMENT: "Nuclear Weapon Involvment",
  HANDHEL_WEAPONS: "Hand Held Weapons",
  PORNOGRAPHY: "Pornograpghy",
};
