import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelectedLanguage } from "language";
import { usePrintStore } from "stores";
import CircularLetter from "./circularGrade";
import * as SC from "./style";

interface Props {
  letterGrade: string;
  grade: number;
  letterGrading: boolean;
  percentageOfEnablingActivities?: number;
  percentageOfDelayingActivities?: number;
  environmental?: any;
  social?: any;
  governance?: any;
}

const ClimateCard = (props: Props) => {
  const { printing } = usePrintStore();
  const { letterGrade, grade, social, governance, environmental } = props;
  const progress = grade > 100 ? 100 : grade < 0 ? 0 : grade;
  const { selectedLanguage, selectedDictionary, selectedDirection } =
    useSelectedLanguage();
  const buildToolTipText = () => {
    let tooltipText = "שיקלול של:";
    let check = true;
    for (let issue of social?.issues || []) {
      if (issue.group === "Climate") {
        check = false;
        tooltipText += `\n - ${issue.label}`;
      }
    }
    for (let issue of governance?.issues || []) {
      if (issue.group === "Climate") {
        check = false;
        tooltipText += `\n - ${issue.label}`;
      }
    }
    for (let issue of environmental?.issues || []) {
      if (issue.group === "Climate") {
        check = false;
        tooltipText += `\n - ${issue.label}`;
      }
    }
    if (check) tooltipText += `\n אף לא אחד`;
    return tooltipText;
  };
  return (
    <SC.Card variant="outlined">
      <CardContent>
        <Typography align="center" variant="h4" gutterBottom>
          {(selectedDictionary as any).climateChangeRating}
        </Typography>
        <Tooltip
          title={
            <div style={{ direction: "rtl", whiteSpace: "pre-line" }}>
              {buildToolTipText()}
            </div>
          }
          placement="right-start"
        >
          <IconButton>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
        <Grid
          container
          p={{ xs: "1rem .8rem 1rem", lg: "2rem 2rem 1rem" }}
          justifyContent="space-between"
          direction="column"
        >
          <Grid item sm={12} xs={printing ? 3 : 12} mx="auto">
            {
              <CircularLetter
                {...{
                  letterGrade: letterGrade,
                  progress: grade,
                  itemSize: printing ? 90 : 120,
                }}
              />
            }
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
            sm={12}
            xs={printing ? 3 : 12}
            mx="auto"
          >
            <Typography>
              {(selectedDictionary as any).expeditingActivities}
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography color="#1d8ea8">{"%"}</Typography>
              <Typography color="#1d8ea8">
                {props.percentageOfEnablingActivities || "-"}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
            sm={12}
            xs={printing ? 3 : 12}
            mx="auto"
          >
            <Typography>
              {(selectedDictionary as any).delayingActivities}
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography color="#1d8ea8">{"%"}</Typography>
              <Typography color="#1d8ea8">
                {props.percentageOfDelayingActivities || "-"}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </SC.Card>
  );
};

export default ClimateCard;
